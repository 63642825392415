import { Component, OnInit, Output, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output() burgerTapped = new Subject<void>();
  authSub = new Subscription();
  isAuth = false;

  constructor(private authService: AuthService, private route: Router) { }

  ngOnInit() {
    this.isAuth = this.authService.isAuthenticated();
    this.authSub = this.authService.authChange.subscribe((newAuthStatus) => {
      this.isAuth = newAuthStatus;
    });
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  onBurgerTapped() {
    this.burgerTapped.next();
  }

  onLogout() {
    this.authService.logout();
    this.route.navigate(['/']);
  }

  onAdd() {
  }
}
