import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  authSub = new Subscription();
  signupForm: FormGroup;
  isAuth = false;
  isLoading = false;
  maxDate;

  constructor(private authService: AuthService, private route: Router) {}

  ngOnInit() {

    this.authSub = this.authService.authChange.subscribe((auth) => {
      if (auth) {
        this.route.navigate(['/notes']);
      }
    });

    this.signupForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9].{8,}'
          )
        ]
      }),
      picker: new FormControl('', {
        validators: [Validators.required]
      }),
      tccheck: new FormControl('', {
        validators: [Validators.required]
      })
    });
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
  }

  onSubmit() {
    this.authService.register({
      email: this.signupForm.value.email,
      password: this.signupForm.value.password,
      birthday: this.signupForm.value.picker
    });
  }
}
