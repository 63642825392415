import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isAuth = false;
  isLoading = false;

  constructor(private authService: AuthService, private route: Router) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(8)
        ],
      })
    });

    this.authService.authChange.subscribe((result) => {
      if (result) {
        this.route.navigate(['/notes']);
      } else {
        this.loginForm.value.password = '';
        this.loginForm.controls.password.setErrors({incorrect: true});
      }
    });
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }
    this.authService.login( {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    });
  }

}
