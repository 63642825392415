import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AngularSplitModule } from 'angular-split';
import { AngularResizedEventModule } from 'angular-resize-event';

import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavComponent } from './navigation/sidenav/sidenav.component';
import { NoteListComponent } from './notes/notelist/notelist.component';
import { NoteEditorComponent } from './notes/editor/editor.component';
import { NoteViewerComponent } from './notes/viewer/viewer.component';
import { NotesComponent } from './notes/notes.component';
import { HomeComponent } from './pages/home/home.component';
import { TagListComponent } from './notes/taglist/taglist.component';
import { AuthService } from './auth/auth.service';
import { NoteControlService } from './notes/note-control.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { DeleteConfirmationDialogComponent } from './notes/notelist/delete-confirmation-dialog.component';
import { NoteDataService } from './notes/note-data.service';
import { environment } from 'src/environments/environment';
import { TestComponent } from './pages/test/test.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    HeaderComponent,
    SidenavComponent,
    NoteListComponent,
    NoteEditorComponent,
    NoteViewerComponent,
    NotesComponent,
    HomeComponent,
    TagListComponent,
    DeleteConfirmationDialogComponent,
    TestComponent,
  ],
  imports: [
    AngularResizedEventModule,
    AngularSplitModule.forRoot(),
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    AppRoutingModule
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    {provide: FirestoreSettingsToken, useValue: {}},
    AuthService,
    NoteDataService,
    NoteControlService
  ],
  entryComponents: [
    DeleteConfirmationDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
