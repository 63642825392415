import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Note } from './models/note';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class NoteDataService {

    notes: Note[];
    noteDataUpdated = new Subject<Note[]>();
    newNoteAdded = new Subject<Note>();
    tagUpdated = new Subject<string[]>();
    notesCollection: AngularFirestoreCollection<Note>;
    notesSub = new Subscription();

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private authService: AuthService) {

        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.notesCollection = this.afs.collection<Note>('notes', ref => ref.where('uid', '==', this.afAuth.auth.currentUser.uid));
                this.notesSub = this.notesCollection.valueChanges().subscribe( (notes: Note[]) => {
                    this.notes = notes.map(note => {
                        return {
                            ...note
                        };
                    });
                    this.noteDataUpdated.next(this.notes);
                    this.tagUpdated.next(this.getTags());
                });
            } else {
                if (this.notesSub) {
                    this.notesSub.unsubscribe();
                }
            }
        });

        this.authService.authChange.subscribe((auth) => {
            if (!auth) {
                this.clearState();
            }
        });
    }

    clearState() {
        this.notes = [];
        this.notesCollection = null;
        if (this.notesSub) {
            this.notesSub.unsubscribe();
            this.notesSub = null;
        }
    }

    getNotes(): Note[] {
        return this.notes;
    }

    getTags(): string[] {
        const tags = [];
        for (const note of this.notes) {
            if ( note.tags ) {
                for (const tag of note.tags ) {
                    if ( tags.indexOf(tag) === -1 ) {
                        tags.push(tag);
                    }
                }
            }
        }
        tags.sort();
        return tags;
    }

    saveNote(note: Note) {
        this.notesCollection.doc(note.id).set(note);
    }

    deleteNote(note: Note) {
        this.notesCollection.doc(note.id).delete();
    }

    addNote() {
        const newId = this.afs.createId();
        const newNote = {
            id: newId,
            uid: this.afAuth.auth.currentUser.uid,
            title: 'Untitled',
            body: '',
            lastupdate: '',
            tags: []
          };
        this.notesCollection.doc(newId).set(newNote)
        .then((result) => {
            this.newNoteAdded.next(newNote);
        })
        .catch((reason) => {

        });
    }

}
