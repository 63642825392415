import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Note } from './models/note';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoteControlService {

  private currentNote: Note;
  editStatusUpdated = new Subject<boolean>();
  currentChanged = new Subject<Note>();
  saveRequested = new Subject<void>();
  saveExecuted = new Subject<Note>();
  filterTagUpdated = new Subject<string[]>();
  private isInEdit = false; // Default Note Style

  constructor(private authService: AuthService) {
    this.authService.authChange.subscribe((auth) => {
      if (!auth) {
        this.clearState();
      }
    });
  }

  clearState() {
    this.currentNote = null;
    this.isInEdit = false;
  }

  getCurrentNote(): Note {
    return this.getCloneNote(this.currentNote);
  }

  getEmptyNote(): Note {
    const note = {
      id: '',
      title: '',
      body: '',
      uid: '',
      lastupdate: '',
      tags: []
    };
    return note;
  }

  getCloneNote(note: Note) {
    if (note) {
      return {
        ...note,
        tags: [...note.tags]
      };
    } else {
      return this.getEmptyNote();
    }
  }

  setEditMode(b: boolean) {
    this.isInEdit = b;
    this.editStatusUpdated.next(this.isInEdit);
  }

  setCurrentNote(note: Note) {
    this.currentNote = this.getCloneNote(note);
    this.currentChanged.next(this.currentNote);
  }

  requestSave() {
    this.saveRequested.next();
  }

  setFilterTags(tags: string[]) {
    this.filterTagUpdated.next(tags);
  }
}
