import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MatSlideToggle } from '@angular/material';
import { Note } from '../models/note';
import { NoteControlService } from '../note-control.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog.component';
import { NoteDataService } from '../note-data.service';

@Component({
  selector: 'app-note-list',
  templateUrl: './notelist.component.html',
  styleUrls: ['./notelist.component.css']
})
export class NoteListComponent implements OnInit, OnDestroy {

  notes: Note[];
  saveSub = new Subscription();
  newNoteSub = new Subscription();
  noteUpdatedSub = new Subscription();
  filterTagSub = new Subscription();
  isEdit = false;
  displayedColumns: string[] = ['title', 'lastupdate', 'tags'];
  dataSource = new MatTableDataSource<Note>();
  deleteConfirmed = false;
  selectedRowId = '';
  selectedTags = [];
  @ViewChild('editToggle') editToggleRef: MatSlideToggle;

  constructor(
    private noteData: NoteDataService,
    private noteControl: NoteControlService,
    private dialog: MatDialog) {}

  ngOnInit() {
    this.noteUpdatedSub = this.noteData.noteDataUpdated.subscribe((notes: Note[]) => {
      if (!this.editToggleRef.checked) {
        this.setEditMode(false);
      }
      if (!notes) {
        this.notes = [];
      }
      this.notes = notes;
      if (!this.selectedRowId && this.notes.length > 0) {
        this.setCurrentNote(this.notes[0]);
      }
      this.updateDataSource();
    });

    this.newNoteSub = this.noteData.newNoteAdded.subscribe( (newNote) => {
      this.setCurrentNote(newNote);
      this.setEditMode(true);
    });

    this.filterTagSub = this.noteControl.filterTagUpdated.subscribe( (selectedTags) => {
      if (this.isEdit) {
        return;
      }
      this.selectedTags = [...selectedTags];
      this.updateDataSource();
    });
  }

  ngOnDestroy() {
    if (this.saveSub) {
      this.saveSub.unsubscribe();
    }
    if (this.noteUpdatedSub) {
      this.noteUpdatedSub.unsubscribe();
    }
  }

  updateDataSource() {
    if (!this.selectedTags || this.selectedTags.length === 0) {
      this.selectedTags = [];
      this.dataSource.data = this.notes;
      return;
    }
    const filteredNotes = [];
    let containsSelected = false;
    for (const note of this.notes) {
      for (const selectedTag of this.selectedTags) {
        if ( note.tags.indexOf( selectedTag ) !== -1
        || (this.isEdit && note.id && note.id === this.selectedRowId) ) {
          if (note.id === this.selectedRowId) {
            containsSelected = true;
          }
          filteredNotes.push(note);
          break;
        }
      }
    }
    if (!containsSelected) {
      if ( filteredNotes.length > 0 ) {
        this.selectedRowId = filteredNotes[0].id;
        this.noteControl.setCurrentNote(filteredNotes[0]);
      } else {
        this.selectedRowId = '';
        this.noteControl.setCurrentNote(null);
      }
    }
    this.dataSource.data = filteredNotes;
  }

  setCurrentNoteByIndex(index) {
    if ( index < this.notes.length) {
      this.noteControl.setCurrentNote(this.notes[index]);
      this.selectedRowId = this.notes[index].id;
    }
  }

  setCurrentNote(note: Note) {
    this.selectedRowId = note.id;
    this.noteControl.setCurrentNote(note);
  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSave() {
    this.noteControl.requestSave();
  }

  onAdd() {
    this.noteData.addNote();
  }

  onDelete() {
    const currentNote = this.noteControl.getCurrentNote();
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '350px'
    }).afterClosed().subscribe(result => {
        if ( result === true ) {
          let nextSelectedNote: Note;
          nextSelectedNote = this.getSelectedNoteAfterDeletion(currentNote);
          this.noteData.deleteNote(currentNote);
          this.setCurrentNote(nextSelectedNote);
          this.setEditMode(false);
        }
    });
  }

  setEditMode(b: boolean) {
    this.editToggleRef.checked = b;
    this.isEdit = b;
    this.noteControl.setEditMode(this.isEdit);
    if (!this.isEdit) {
      this.updateDataSource();
    }
  }

  getSelectedNoteAfterDeletion(note: Note): Note {
    let currIndex = -1;
    if (this.notes.length === 1) {
      return {
        id: '',
        title: '',
        body: '',
        tags: []
      } as Note;
    }
    for (let i = 0; i < this.notes.length; i++) {
      if (this.notes[i].id === note.id) {
        currIndex = i;
        break;
      }
    }
    if ( currIndex === -1) {
      return {} as Note;
    }
    if (currIndex === (this.notes.length - 1)) {
      return this.notes[currIndex - 1];
    } else {
      return this.notes[currIndex + 1];
    }
  }

  selectRow(row) {
    if (this.isEdit) {
      return;
    }
    const note = {
      ...row,
    };
    this.selectedRowId = row.id;
    this.noteControl.setCurrentNote(note);
  }

  onChipClicked(tag) {
  }

  onEditToggleChanged(editToggle) {
    if (editToggle.checked) {
      if (this.notes.length === 0) {
        this.noteData.addNote();
      } else {
        this.setEditMode(true);
      }
    } else {
      this.noteControl.requestSave();
    }
  }
}

