import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit, OnDestroy {

  @Output() itemClick = new Subject<void>();
  isAuth = false;
  authSub = new Subscription();

  constructor(private authService: AuthService, private route: Router) { }

  ngOnInit() {
    this.isAuth = this.authService.isAuthenticated();
    this.authSub = this.authService.authChange.subscribe((newAuthStatus) => {
      this.isAuth = newAuthStatus;
    });
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  onLogout() {
    this.authService.logout();
    this.itemClick.next();
    this.route.navigate(['/']);
  }

  onItemClick() {
    this.itemClick.next();
  }

}
