import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ResizedEvent } from 'angular-resize-event';
import { MatChipInputEvent } from '@angular/material';

import { Note } from '../models/note';
import { NoteControlService } from '../note-control.service';
import { Subscription } from 'rxjs';
import { NoteDataService } from '../note-data.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-note-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class NoteEditorComponent implements OnInit {

  noteForm: FormGroup;
  saveSub = new Subscription();
  note: Note;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private authService: AuthService,
    private noteControl: NoteControlService,
    private noteData: NoteDataService ) { }

  ngOnInit() {

    this.note = this.noteControl.getCurrentNote();

    this.noteForm = new FormGroup({
      title: new FormControl(this.note.title),
      body: new FormControl(this.note.body),
    });

    this.saveSub = this.noteControl.saveRequested.subscribe(() => {
      const timeStamp = new Date().toLocaleString();
      const note = this.noteControl.getCurrentNote();
      // Update Fields
      note.title = this.noteForm.value.title;
      note.body = this.noteForm.value.body;
      note.lastupdate = timeStamp;
      note.tags = this.note.tags;
      // Save
      this.noteControl.setCurrentNote(note);
      this.noteData.saveNote(note);
    });

    this.authService.authChange.subscribe((auth) => {
      if (!auth) {
        this.note = null;
        if (this.saveSub) {
          this.saveSub.unsubscribe();
          this.saveSub = null;
        }
      }
    });
  }

  onResized(event: ResizedEvent) {
    const form = document.getElementById('note-form');
    const textArea = document.getElementById('noteTextArea');
    const formRect = form.getBoundingClientRect();
    const h = formRect.height - 138;
    textArea.style.height = `${h}px`;
  }

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.note.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: string) {
    const index = this.note.tags.indexOf(tag);

    if (index >= 0) {
      this.note.tags.splice(index, 1);
    }
  }
}
