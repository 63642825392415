import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AuthData } from './models/auth-data.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { RegisterData } from './models/register-data.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authChange = new Subject<boolean>();
  private isAuth = false;

  constructor(private afAuth: AngularFireAuth) {
  }

  register(registerData: RegisterData) {
    this.afAuth.auth.createUserWithEmailAndPassword(registerData.email, registerData.password)
    .then((auth) => {
      this.isAuth = true;
      this.publishChange();
    })
    .catch((error) => {
      this.isAuth = false;
      this.publishChange();
    });
  }

  login(authData: AuthData) {
    this.afAuth.auth.signInWithEmailAndPassword(authData.email, authData.password)
    .then((user) => {
      this.isAuth = true;
      this.authChange.next(true);
    })
    .catch( (error) => {
      this.isAuth = false;
      this.authChange.next(false);
    });
  }

  logout() {
    this.afAuth.auth.signOut()
    .then(() => {
      this.isAuth = false;
      this.publishChange();
    })
    .catch((error) => {
    });
  }

  publishChange() {
    this.authChange.next(this.isAuth);
  }

  isAuthenticated() {
    return this.isAuth;
  }
}
