import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  constructor(private afAuth: AngularFireAuth) { }

  ngOnInit() {
  }

  click1() {
    console.log('test1');
    console.log('currentUser = ');
    console.log(this.afAuth.auth.currentUser);
    // this.afAuth.auth.uid --> uid
  }

}
