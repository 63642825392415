import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { NoteDataService } from '../note-data.service';
import { Subscription } from 'rxjs';
import { NoteControlService } from '../note-control.service';
import { Tag } from '../models/tag';
import { MatSelectionList } from '@angular/material';

@Component({
  selector: 'app-note-taglist',
  templateUrl: './taglist.component.html',
  styleUrls: ['./taglist.component.css']
})
export class TagListComponent implements OnInit, OnDestroy {

  tagSub = new Subscription();
  selectedTags = [];
  tags: Tag[];

  constructor(
    private noteData: NoteDataService,
    private noteControl: NoteControlService) {
  }

  ngOnInit() {
    this.tagSub = this.noteData.tagUpdated.subscribe((tgs) => {
      this.updateTagList(tgs);
    });
  }

  ngOnDestroy() {
    if (this.tagSub) {
      this.tagSub.unsubscribe();
    }
  }

  updateTagList(tags: string[]) {
    if (tags && tags.length > 0) {
      this.tags = tags.map( (tag) => {
        const sel = this.selectedTags.indexOf(tag) !== -1;
        return {name: tag, selected: sel};
      });
    } else {
      this.tags = [];
      this.selectedTags = [];
    }
  }

  onClick(list) {
    this.selectedTags = list.selectedOptions.selected.map(item => item.value);
    this.noteControl.setFilterTags(this.selectedTags);
  }

}
