import { Component, OnInit, OnDestroy } from '@angular/core';
import { Note } from '../models/note';
import { NoteControlService } from '../note-control.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-note-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})
export class NoteViewerComponent implements OnInit, OnDestroy {

  noteSub = new Subscription();
  note = {
    tags: []
  };

  constructor(
    private authService: AuthService,
    private noteControl: NoteControlService) {}

  ngOnInit() {
    this.note = this.noteControl.getCurrentNote();
    this.noteSub = this.noteControl.currentChanged.subscribe((note: Note) => {
      this.note = {...note};
    });

    this.authService.authChange.subscribe((auth) => {
      if (!auth) {
        this.note = null;
        if (this.noteSub) {
          this.noteSub.unsubscribe();
          this.noteSub = null;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.noteSub) {
      this.noteSub.unsubscribe();
    }
  }

}
