import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { NoteControlService } from './note-control.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notes',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit, OnDestroy {

  editSub = new Subscription();
  private isEdit = false;

  constructor(private noteControl: NoteControlService) { }

  ngOnInit() {
    this.editSub = this.noteControl.editStatusUpdated.subscribe((isEdit) => {
      this.isEdit = isEdit;
    });
  }

  ngOnDestroy() {
    if (this.editSub) {
      this.editSub.unsubscribe();
    }
  }
}
